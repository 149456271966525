/* #######################################
            Mat Table Overrides
     Most mat table styling live in base-table.scss, but these are
     for styles that need to applied in a global file
   ####################################### */

/**
 * Allows to table to scroll its content and stickies header on top
 */
.table-container.scroll-table {
  height: 100%;
  display: flex;
  flex-direction: column;

  .table-filters-top,
  .table-filters-bottom {
    flex: 0 0 auto;
  }

  div.mat-mdc-table {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    mat-table.mat-mdc-table {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;

      overflow-y: auto;
      overflow-x: auto;

      &::-webkit-scrollbar,
      > ::-webkit-scrollbar {
        -webkit-appearance: none !important;
        width: 7px !important;
        height: 7px !important;
      }
      &::-webkit-scrollbar-thumb,
      > ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5) !important;
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
      }

      mat-row {
        flex: 1 0 auto;
      }

      mat-header-row {
        position: sticky;
        top: 0;
        z-index: 10;
        flex: 1 0 auto;
        max-height: 70px;

        // Lets make allow our cells to overflow past 70px
        mat-header-cell {
          overflow-x: hidden;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start; // Make sure scrolling start at top
          align-items: flex-start;

          .mat-sort-header-container {
            flex: 0 1 auto;
            max-width: 100%;
            margin: auto 0; // But we still want to non scrolling columns to be centered

            .mat-sort-header-button {
              flex: 1 1 0;
              word-break: break-word;
            }

            .mat-sort-header-arrow {
              flex: 0 0 auto;
            }
          }
        }

        mat-header-cell::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}
