@import "./variables";
@import "./mixins";

.report-container {
  margin: 0;
  padding: $content-separation 0 $content-separation * 2;

  @media #{$lg-query} {
    height: 100%;
  }

  .report-single-pane,
  .report-left-pane,
  .report-right-pane {
    display: flex;
    flex-direction: column;

    margin-left: $content-separation;

    @media #{$max-md-query} {
      margin-bottom: $content-separation;
    }
    @media #{$lg-query} {
      height: 100%;
    }

    .empty-report-content {
      @include content-container();
      flex: 0 1 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $content-padding;

      color: $gray-text-color;
      font-size: 18px;
      font-weight: 300;
    }

    .report-content-no-container {
      flex: 0 1 auto;
      display: flex;
      flex-direction: column;
    }

    .report-content {
      @include content-container();
      flex: 0 1 auto;
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        margin-top: $content-separation;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      mat-tab-group {
        flex: 0 0 auto;
      }

      .report-content-header {
        @include content-header();
        display: flex;
        flex-flow: row wrap;
        flex: 0 0 auto;

        .header-text {
          flex: 1 0 0;
        }

        .header-description {
          flex: 0 1 auto;
          display: flex;
          justify-content: flex-end;
          font-weight: 300;
          color: $description-text-color;
        }

        .header-buttons {
          flex: 0 1 auto;
        }
      }

      .report-content-table,
      .report-content-custom {
        @include content-container();
        flex: 0 1 auto;
        display: flex;
        flex-direction: column;
        border-radius: 0;
      }

      .report-content-form {
        @include content-form-body();
        flex: 0 1 auto;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
}
