@import "./variables";

/**
 ****************************************
 *              Misc Mixins             *
 ****************************************
 */

@mixin glowing-text($glow-light, $glow-dark) {
  @keyframes glowing-text {
    0% {
      color: $glow-light;
      text-shadow: 1px 1px 3px rgba($glow-light, 0.5);
    }
    50% {
      color: $glow-dark;
      text-shadow: 0 0 1px rgba($glow-dark, 0.5);
    }
    100% {
      color: $glow-light;
      text-shadow: 1px 1px 3px rgba($glow-light, 0.5);
    }
  }
  animation: glowing-text 1300ms infinite;
}

@mixin glowing-button($glow-light, $glow-dark) {
  @keyframes glowing-button {
    0% {
      background-color: $glow-light;
      border-color: $glow-light;
      box-shadow: 0 0 5px 1.5px rgba($glow-light, 0.5);
    }
    50% {
      background-color: $glow-dark;
      border-color: $glow-dark;
      box-shadow: 0 0 1px 1px rgba($glow-dark, 0.5);
    }
    100% {
      background-color: $glow-light;
      border-color: $glow-light;
      box-shadow: 0 0 5px 1.5px rgba($glow-light, 0.5);
    }
  }
  animation: glowing-button 1300ms infinite;
}

/**
 ****************************************
 *          Edit Form Mixins             *
 ****************************************
 */

@mixin edit-form-container() {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: $content-item-separation 0;
  background: $form-background;
  border-bottom-left-radius: $content-border-radius;
  border-bottom-right-radius: $content-border-radius;
}

@mixin edit-form-details-group() {
  display: flex;
  flex-direction: column;
  padding: $content-padding;

  @media #{$mobile-query} {
    padding: 8px;
  }
}

@mixin edit-form-details-group-header() {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  color: $gray-text-color;
  padding-bottom: 3px;
  margin-bottom: $content-item-separation;
  border-bottom: $small-border;
}

@mixin edit-form-details-group-body() {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: -$content-item-separation*0.5;

  @media #{$mobile-query} {
    padding: 8px 8px 0;
    margin: -8px;
  }
}

@mixin edit-form-detail-container {
  margin: $content-item-separation*0.5;
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;

  @media #{$mobile-query} {
    margin: 8px;
  }

  > * {
    flex: 5 0 200px;
  }

  > label {
    flex: 1 0 200px;
    line-height: 24px;
    padding: 7px 20px 7px 0;
    color: $lighter-text-color;
    text-align: right;

    .asterisk {
      color: desaturate($red-color, 35); // A more chill red
      margin-right: -14px;
    }
  }

  &.single-toggle-group {
    align-items: center;
  }
}

/**
 ****************************************
 *           Content Mixins             *
 ****************************************
 */
@mixin content-container() {
  background: $primary-content-background;
  border-radius: $content-border-radius;
  overflow: hidden;
  padding: 0;
}

@mixin content-header() {
  min-height: 48px;
  padding: 3px $content-padding;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $primary-content-background;
  font-weight: 600;
  color: $gray-text-color;
  border-bottom: $small-border;

  .header-buttons {
    display: flex;
    flex-flow: row wrap;

    button,
    a.btn,
    .slide-toggle-container {
      margin: $small-button-separation*0.5;
      padding: $small-button-padding;
      font-size: $small-button-font-size;
    }
  }
}

@mixin content-form-body() {
  background: $form-background;
  padding: $content-padding;
}

@mixin content-footer() {
  min-height: 48px;
  padding: 0 $content-padding;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: $primary-content-background;
  font-weight: 600;
  color: $gray-text-color;
  border-top: $small-border;

  button {
    margin: $small-button-separation*0.5;
    padding: $small-button-padding;
    font-size: $small-button-font-size;
  }
}

@mixin no-content-found-text() {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $content-padding;

  font-size: 20px;
  font-weight: 300;
  color: $gray-text-color;
}
