
button.mdc-button {
  // force uppercase text
  text-transform: uppercase;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.3px;
}

button.icon-button {
  height: 32px !important;
  width: 35px !important;
}

button.standard-button {
  height: 48px!important;
  min-height: 48px!important;
}

button.small-button {
  height: 32px !important;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.3px;
}

button.action-button {
  color: $secondary-color !important;
  background-color: #ffffff;
  border: $secondary-color solid 1px;
  box-shadow: none;
}

.success-button {
  color: $light-primary-text !important;
  background-color: $success-color !important;
  border-radius: 4px;
  border: none;
  font-weight: bold!important;
}

.secondary-button {
  color: $light-primary-text !important;
  background-color: $secondary-color !important;
  font-weight: bold!important;
}

.cancel-button {
  color: $blue-header-text !important;
  border: $blue-header-text solid 1px !important;
  box-shadow: none !important;
  font-weight: bold!important;
}

.reject-button {
  color: #ffffff !important;
  border: $reject-color solid 1px !important;
  background-color: $reject-color !important;
  box-shadow: none !important;
  font-weight: bold!important;
}

.disabled-button {
  color: $light-gray-color !important;
  border: $light-gray-color solid 1px !important;
  box-shadow: none !important;
  font-weight: bold!important;
}

.mdc-button.outline-button {
  font-size: $small-button-font-size;
  text-transform: uppercase;
  background-color: white;
  border-radius: 4px;
  border: solid 1px $secondary-color;

  .mdc-button__label {
    color: $secondary-color;
  }

  &:hover {
    background-color: $secondary-color;
    color: $light-primary-text;

    .mdc-button__label {
      color: $light-primary-text;
    }
  }

  &:disabled {
    border: solid 1px $light-gray-color ;
  }
}

.small-button {
  font-size: 12px;
  padding: $small-button-padding;
  line-height: 15px;
}
