@import './report';

@import './themeOverrides';

.form-group {
  margin-bottom: $content-item-separation;
  padding: $content-padding;
  border-radius: $content-border-radius;
}

.help-text {
  padding-bottom: 1rem;
}

.clickable {
  cursor: pointer;
}

.line-broken-tooltip {
  white-space: pre-line !important;
}

.modal-action-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  .outline-button {
    margin-right: $content-padding;
  }
}

.center-element {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-direction {
  flex-direction: column;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: $content-item-separation * 2;

  .half-width-input {
    width: 48%;
    max-height: 56px;
  }
}


