$xs-breakpoint: 0;
$sm-breakpoint: 576px;
$md-breakpoint: 768px;
$lg-breakpoint: 992px;
$xl-breakpoint: 1200px;
$xxl-breakpoint: 1600px;

$md-breakpoints: (
  xs: $xs-breakpoint,
  sm: $sm-breakpoint,
  md: $md-breakpoint,
  lg: $lg-breakpoint,
  xl: $xl-breakpoint,
  xxl: $xxl-breakpoint
);

// KEY DESIGN ELEMENT VARIABLES
// -- colors
$separator-color: #bdbdbd;
$hero-light-text-color: #767c81;
$negative-text-color: #ee6b6b;

// colors
$light-gray-color: rgb(206, 212, 218);
$primary-color: #121146;
$red-color: red;
$secondary-color: #2c89bf;
$success-color: #38a169;
$cancel-red: #ec5a5a;
$orange-color: #f7923d;

// Shadow variables
$shadow-color-base: rgba(0, 0, 0, 0.12);
$shadow-color-dark: rgba(0, 0, 0, 0.14);
$shadow: 0 1px 2px $shadow-color-base, 0 1px 1px $shadow-color-dark;

// Border variable
$border-color: #a8a8a8;
$border-bottom: 1px solid $border-color;

// -- text colors
$description-text-color: #8A96A0;
$gray-text-color: #585c61;
$lighter-text-color: #3b3f44;
$primary-text-color: #212529;
// Material Design Colors text colors
$dark-primary-text: #212529;
$dark-secondary-text: rgba(black, 0.54);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$blue-header-text: #3DA9E8;
$medium-blue-color: #0b4d85;
$disabled-background: #e9ecef;
$reject-color: #ee6b6b;

// Normal Backgrounds
$primary-content-background: #ffffff;
$alternate-content-background: #f7f7f7;
$form-background: #fcfcfc;
$header-background: $primary-color; // desaturate(mix($primary-color, $secondary-color, 30), 20);
$light-blue-background: #E0F0FB;
$card-header-background: #0b4d85;


// Layout variables
$button-icon-spacing: 3px;
$button-padding: 5px 20px;
$button-separation: 6px;
$content-item-separation: 10px;
$content-padding: 15px;
$content-separation: 15px;
$inner-content-padding: 10px;
$large-content-separation: 25px;
$mobile-content-padding: 8px;
$mobile-content-separation: 8px;
$page-header-bottom-padding: 8px;
$small-button-padding: 3px 15px;
$small-button-separation: 4px;
$table-button-padding: 5px 9px;
$table-button-separation: 4px;
$text-icon-spacing: 8px;
$card-margin-top: 30px !default;
$card-margin-bottom: 30px !default;
$card-padding-top: 5px !default;
$card-padding-side: 32px !default;
$card-header-padding-side: 15px !default;
$card-margin-side: 15px !default;
$userlane-padding: 50px;
$column-margin-side: 52px;
$column-margin-bottom: 24px;

// Font variables
$base-font-size: 1rem;
$button-font-size: 13px;
$header-font-size: 12px;
$dialog-header-font-size: 24px;
$header-font-weight: 700;
$heavy-font-weight: 900;
$small-button-font-size: 13px;
$table-button-font-size: 13px;

// Border variables
$bootstrap-border-radius: 0.25rem;
$bootstrap-border: rgb(206, 212, 218) solid 1px;
$border-color: $light-gray-color;
$content-border-radius: 5px;
$small-border: rgba(0, 0, 0, 0.12) solid 1px;
$table-border: rgb(224, 224, 224) solid 1px;
$thiccc-border: rgba(0, 0, 0, 0.12) solid 2px;

// Query variables
$lg-query: '(min-width: ' + $lg-breakpoint + ')';
$max-lg-query: '(max-width: ' + ($lg-breakpoint - 1px) + ')';
$max-md-query: '(max-width: ' + ($md-breakpoint - 1px) + ')';
$max-sm-query: '(max-width: ' + ($sm-breakpoint - 1px) + ')';
$max-xl-query: '(max-width: ' + ($xl-breakpoint - 1px) + ')';
$max-xxl-query: '(max-width: ' + ($xxl-breakpoint - 1px) + ')';
$md-query: '(min-width: ' + $md-breakpoint + ')';
$mobile-query: '(max-width: 450px)';
$not-mobile-query: '(min-width: 451px)';
$sm-query: '(min-width: ' + $sm-breakpoint + ')';
$small-mobile-query: '(max-width: 374px)';
$xl-query: '(min-width: ' + $xl-breakpoint + ')';
$xxl-query: '(min-width: ' + $xxl-breakpoint + ')';

// Navbar breakpoints
$above-navbar-dropdown-breakpoint: $sm-query;
$below-navbar-dropdown-breakpoint: $max-sm-query;
$below-navbar-text-breakpoint: $max-lg-query;

// Box Shadow options
$mat-elevation-z1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
  0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
$mat-elevation-z2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
$mat-elevation-z3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
  0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
$mat-elevation-z4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
  0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
