/**
 * The entrypoint for all of the application's CSS styles.
 */
@use '@angular/material' as mat;
@include mat.core();
@import url('https://cdn.jsdelivr.net/npm/toastify-js/src/toastify.min.css');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import 'theme/_variables';
@import 'theme/_global';
@import 'theme/_buttons';

$hero-secondary-palette: (
  50: #e5e6ee,
  100: #bdc0d6,
  200: #9398ba,
  300: #4f548c,
  400: #33387b,
  500: #33387b,
  600: #2d3273,
  700: #262968,
  800: #1e205c,
  900: #121146,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);

$hero-primary-palette: (
  50 : #e6f1f7,
  100 : #c0dcec,
  200 : #96c4df,
  300 : #6bacd2,
  400 : #4c9bc9,
  500 : #2c89bf,
  600 : #2781b9,
  700 : #2176b1,
  800 : #1b6ca9,
  900 : #10599b,
  A100 : #cce5ff,
  A200 : #99caff,
  A400 : #66b0ff,
  A700 : #4da3ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$hero-tertiary-palette: (
  50: #eceff1,
  100: #cfd8dc,
  200: #b0bec5,
  300: #90a4ae,
  400: #78909c,
  500: #607d8b,
  600: #546e7a,
  700: #455a64,
  800: #37474f,
  900: #263238,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);

$deep-orange-palette: (
  50: #fbe9e7,
  100: #ffccbc,
  200: #ffab91,
  300: #ff8a65,
  400: #ff7043,
  500: #ff5722,
  600: #f4511e,
  700: #e64a19,
  800: #d84315,
  900: #bf360c,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);

$color-palette-error: (
  50 : #ffe9ec,
  100 : #ffc9cc,
  200 : #ef9a9a,
  300 : #f6928e,
  400 : #f63f38,
  500 : #f92712,
  600 : #eb1515,
  700 : #d90010,
  800 : #cd0006,
  900 : #bf0000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);

$color-palette-warn: (
  50 : #fff3e0,
  100 : #ffe0b2,
  200 : #ffcd80,
  300 : #ffb84d,
  400 : #ffca28,
  500 : #ffa826,
  600 : #fb8d00,
  700 : #f57d00,
  800 : #ef6d00,
  900 : #e65200,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);

$color-palette-success: (
  50 : #e7f5ed,
  100 : #c5e6d3,
  200 : #a1d7b8,
  300 : #7ac89c,
  400 : #5dbc88,
  500 : #3fb074,
  600 : #38a169,
  700 : #308f5c,
  800 : #2b7d51,
  900 : #225e3c,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text
  )
);

$color-palette-error: mat.define-palette($color-palette-error, 600);
$color-palette-warn: mat.define-palette($color-palette-warn, 500);
$color-palette-success: mat.define-palette($color-palette-success, 600);

$hero-primary: mat.define-palette($hero-primary-palette, 500, 800, 100);
$hero-secondary: mat.define-palette($hero-secondary-palette, 900, 900, 100);
$hero-accent: mat.define-palette($hero-tertiary-palette, 800, 900, 100);
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('assets/font/Open_Sans/static/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('assets/font/Open_Sans/static/OpenSans-SemiBold.ttf') format('truetype');
}

$hero-typography: mat.define-typography-config(
  $font-family: 'Open Sans, sans-serif'
);

$hero-theme: mat.define-light-theme(
    (
      color: (
        primary: $hero-primary,
        accent: $hero-secondary,
        warn: $color-palette-warn,
      ),
      typography: $hero-typography
    )
);

@include mat.all-component-themes($hero-theme);

html,
body {
  height: 100%;
  overflow: hidden;
}
body {
  margin: 0;
  letter-spacing: -0.4px;
  font-family: 'Open Sans', sans-serif;
}

mat-form-field.full-width-input {
  display: flex;
  flex-direction: column;
}



div.singleColumnLayout,
form.singleColumnLayout {
  margin: $column-margin-bottom $column-margin-side $card-margin-bottom;
  background: $primary-content-background;
  max-width: 100%;

}

div.twoColumnLayout,
form.twoColumnLayout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  margin: $column-margin-bottom $column-margin-side $card-margin-bottom;

  div.column,
  mat-card.twoColumnLayout {
    align-self: start;
    @media (max-width: map-get($md-breakpoints, md)) {
      width: 100%;
      padding-left: 0;
      grid-column: span 2; /* Make the card span 2 columns */
    }
  }
  div.column-right {
    padding-left: 0 !important;
    width: calc(100% - $content-separation);
  }
}

.mat-mdc-menu-content {
  padding: 1px 0 0 !important;
}



.mat-horizontal-stepper-content {
  display: flex;
  flex-direction: column;
}

mat-card {
  height: 100%;

  mat-card-header {
    background-color: $card-header-background;
    border-radius: 4px 4px 0 0;
    color: $light-primary-text;
    font-size: 12px !important;
    font-weight: 700 !important;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    vertical-align: text-top;
    height: 40px;
    padding: 0 $card-header-padding-side 0 $card-header-padding-side !important;
    text-transform: uppercase;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .card-header-buttons {
      display: flex;
      align-items: center;
    }

    .mat-mdc-card-title {
        font-size: 12px !important;
        font-weight: 700 !important;

    }
  }

  mat-card-content, .mat-mdc-card-content {
    padding: $card-padding-side $card-padding-side 0 $card-padding-side;
    height: 100%;
  }
}

.mdc-text-field, .mat-mdc-form-field-flex, .mat-mdc-form-field-infix {
  height: 48px!important;
  min-height: 48px!important;
  padding-top: 4px!important;
  padding-bottom: 4px!important;
}

// when there is at least one chip
// in the grid, the tabindex is set to 0
// when empty it is set to -1
// this is a workaround to make the chips
// align with the input field correctly
// when the grid is empty or not
mat-chip-grid[tabindex="0"] {
  position: relative;
  top: -8px;
}
